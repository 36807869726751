.container h4 {
    margin-top: 40px;
}

.container hr {
    margin-bottom: 30px;
}

.widthField {
    width: 90%;
    margin-bottom: 15px;
}