.footerContainer {
    height: 98px;
    width: 100%;
    background: #00071A;
    display: flex;
    bottom: 0px;
    position: absolute;
    z-index: 100;
}
.colorWhite {
    color: #fff
}