body{
    background: #e9fffd;
}
.imgInLogin{
    width: 100%;
    height: 100vh;
    margin-bottom: -5px;
}
.loginContainer{
    padding: 80px 50px 0px 50px;
}
.loginContainer .title{
    margin-bottom: 15px;
}
.loginContainer .inputWidth{
    width: 60%;
}
.loginContainer .errorMessage{
    color: red;
    margin-top: 10px;
}
.textAlign{
    text-align: center;
}
.formContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.formContainer .linkMargin{
    margin: 50px 0px 0px 0px;
}
.formContainer .linkMargin a{
    color: #0000EE;
}
.formContainer .linkMargin a:focus {
    color: #0000EE;
}

@media (max-width: 600px) {
    .loginContainer .title {
        font-size: 22px;
    }
    .loginContainer .inputWidth{
        width: 90%;
    }
    .loginContainer .errorMessage{
        font-size: 14px;
    }
}